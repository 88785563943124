<template>
  <div class="d-flex justify-center">
    <user-profile />
  </div>
</template>

<script>
import UserProfile from '@/components/pages/UserProfile.vue';
export default {
  data: () => ({}),
  components: { UserProfile },
  mounted() {
    // this.$store.dispatch('startIdleTracker');
  },
};
</script>
