<template>
  <div class="col-9">
    <v-card>
      <v-toolbar flat color="primary" dark>
        <v-toolbar-title>Личный кабинет</v-toolbar-title>
      </v-toolbar>

      <v-row>
        <v-col>
          <div
            style="font-weight: 500; border-bottom: solid 1px lightgray;"
            class="pl-4 pr-4 pb-4 mb-4 mt-2"
            align="right"
          >
            <div v-if="login == true">
              <v-btn
                plain
                elevation="1"
                class="mr-4"
                @click="passwordDialog = true"
              >
                <v-icon left>mdi-pencil</v-icon>
                Изменить пароль
              </v-btn>

              <v-btn plain elevation="1" @click="logout">
                <v-icon left>mdi-logout</v-icon>
                Выйти
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <div style="font-weight: 500;" class="pl-4 pr-4 mt-6 mb-6">
            <v-icon left>
              mdi-account
            </v-icon>
            Персональная информация
          </div>

          <div class="pl-4 pr-4 mt-6 mb-6">
            <div style="color: darkgray; font-size: 14px;">Имя, фамилия</div>
            <div style="font-weight: 400;">
              {{ profileInfo.user_data.fname }}
              {{ profileInfo.user_data.lname }}
            </div>
          </div>

          <div class="pl-4 pr-4 mt-6 mb-2">
            <div style="color: darkgray; font-size: 14px;">Дата рождения</div>
            <div style="font-weight: 400;">
              {{ profileInfo.user_data.birthday }}
            </div>
          </div>
        </v-col>

        <v-col>
          <div style="font-weight: 500;" class="pl-4 pr-4 mt-6 mb-6">
            <v-icon left>
              mdi-account-voice
            </v-icon>
            Контактная информация
          </div>

          <div class="pl-4 pr-4 mt-6 mb-6">
            <div style="color: darkgray; font-size: 14px;">Телефон</div>
            <div style="font-weight: 400;">
              {{ profileInfo.user_data.phone }}
            </div>
          </div>

          <div class="pl-4 pr-4 mt-6 mb-2">
            <div style="color: darkgray; font-size: 14px;">Email</div>
            <div style="font-weight: 400;">
              {{ profileInfo.user_data.email }}
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="col col-6">
          <div style="font-weight: 500;" class="pl-4 pr-4 mt-6 mb-6">
            <v-icon left>
              mdi-text
            </v-icon>
            Дополнительная информация
          </div>

          <div class="pl-4 pr-4 mt-6 mb-6">
            <div style="color: darkgray; font-size: 14px;">Бонусы</div>
            <div style="font-weight: 400;">
              {{ profileInfo.user_data.bonus }}
            </div>
          </div>

          <div class="pl-4 pr-4 mt-6 mb-6">
            <div style="color: darkgray; font-size: 14px;">Ваш реферер</div>
            <div style="font-weight: 400;">
              {{ profileInfo.referer_data.fname }}
              {{ profileInfo.referer_data.lname }}
            </div>
          </div>

          <div class="pl-4 pr-4 mt-6 mb-6">
            <div style="color: darkgray; font-size: 14px;">Ваш промокод</div>
            <div style="font-weight: 400;">
              <span id="copy-promo">{{ profileInfo.user_data.customer_promo }}</span>
              <v-btn icon @click="copyPromo" title="Скопировать промокод">
                <v-icon>
                  mdi-file-multiple-outline
                </v-icon>
              </v-btn>
            </div>
          </div>

          <div class="pl-4 pr-4 mt-6 mb-6">
            <div style="color: darkgray; font-size: 14px;">
              Ссылка с промокодом для приглашения
            </div>
            <div style="font-weight: 400;">
              <span id="copy-promo-link">{{ profileInfo.user_data.promo_link }}</span>
              <v-btn icon @click="copyPromoLink" title="Скопировать ссылку">
                <v-icon>
                  mdi-file-multiple-outline
                </v-icon>
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col col-12 pb-0">
          <div style="font-weight: 500;" class="pl-4 pr-4 mt-6 mb-6">
            <v-icon left>
              mdi-account-group
            </v-icon>
            Ваши рефералы
          </div>

          <template>
            <v-simple-table height="300px">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Имя
                    </th>
                    <th class="text-left">
                      Телефон
                    </th>
                    <th class="text-left">
                      Суммарное количество бонусов
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="referal in profileInfo.referal_data.referals_1"
                    :key="referal.name"
                  >
                    <td>{{ referal.name }}</td>
                    <td>{{ referal.phone }}</td>
                    <td>{{ referal.summary_referer_bonus }}</td>
                  </tr>
                  <tr
                    v-for="referal in profileInfo.referal_data.referals_2"
                    :key="referal.name"
                  >
                    <td>{{ referal.name }}</td>
                    <td>{{ referal.phone }}</td>
                    <td>{{ referal.summary_referer_bonus }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>

          <!-- <v-data-table
            :headers="headers"
            :items="referals"
            :items-per-page="5"
            class="elevation-1"
          ></v-data-table> -->
        </v-col>
      </v-row>
    </v-card>

    <v-dialog v-model="passwordDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h6">Смена пароля</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Новый пароль*"
                    :rules="[].concat($globals.isPasswordRule)"
                    v-model="passwordChangeData.data.new_password"
                    name="new_password"
                    type="password"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Подтверждение нового пароля*"
                    hint="Значения в этом и предыдущем поле должны совпадать"
                    type="password"
                    :rules="[
                      (val) =>
                        val === passwordChangeData.data.new_password ||
                        'Значения в этом и предыдущем полях не совпадают',
                      (val) =>
                        (val && val.length > 0) ||
                        'Пожалуйста, подтвердите ваш пароль',
                    ]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*поля обязательны для заполнения</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="passwordDialog = false">
            <v-icon>mdi-close</v-icon>
            Отменить
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="password_change"
            :disabled="!valid"
          >
            <v-icon>mdi-check</v-icon>
            Принять
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="passwordResponce" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h6">Смена пароля</span>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-card-text v-if="errorChangePassword == true">
          <p>Во время смены пароля произошла ошибка!</p>
          <p>Пожалуйста, повторите попытку позже.</p>
        </v-card-text>
        <v-card-text v-if="successChangePassword == true">
          <p>Ваш пароль успешно изменён!</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="passwordResponce = false">
            <v-icon>mdi-close</v-icon>
            Закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import qs from 'qs';

export default {
  data: () => ({
    profileInfo: {
      user_data: {
        fname: 'fname',
        lname: 'lname',
        birthday: 'birthday',
        phone: 'phone',
        email: 'email',
        bonus: 'bonus',
        customer_promo: 'customer_promo',
        promo_link: 'promo_link',
      },
      referer_data: {
        fname: 'referer_fname',
        lname: 'referer_lname',
      },
      referal_data: {
        referals_1: [
          {
            name: 'name',
            phone: 'phone',
            summary_referer_bonus: 'summary_referer_bonus',
          },
        ],
        referals_2: [
          {
            name: 'referal_2_name',
            phone: 'referal_2_phone',
            summary_referer_bonus: 'summary_referer_2_bonus',
          },
        ],
      },
    },
    login: false,
    valid: true,
    errorChangePassword: false,
    successChangePassword: false,
    passwordChangeData: {
      data: {
        new_password: '',
      },
    },
    passwordDialog: false,
    passwordResponce: false,
  }),
  methods: {
    async copyPromo() {
      var text = (document.querySelector('#copy-promo')).innerHTML;
      await navigator.clipboard.writeText(text);
    },
    async copyPromoLink() {
      var text = (document.querySelector('#copy-promo-link')).innerHTML;
      await navigator.clipboard.writeText(text);
    },
    logout() {
      this.$http({
        method: 'get',
        url: '/udata/users/logout/.json',
      }).then((response) => {
        if (response.data) {
          window.location.href = 'https://lk.k7.su';
        }
      });
    },
    isLogin() {
      this.$http({
        method: 'get',
        url: '/udata/users/is_auth/.json',
      }).then((response) => {
        if (response.data.result == 1) {
          this.login = true;
        }
      });
    },
    getProfileInfo() {
      this.$http({
        method: 'get',
        url: '/udata/users/get_profile_info/.json',
      }).then((response) => {
        if (response.data) {
          this.profileInfo = response.data;
          console.log(response.data);
        } else {
          console.log('error');
        }
      });
    },
    password_change() {
      this.errorChangePassword = false;
      this.successChangePassword = false;
      if (!this.$refs.form.validate()) {
        return;
      }

      this.$http({
        method: 'post',
        url: '/udata/users/change_user_password/.json',
        data: qs.stringify(this.passwordChangeData),
      }).then((response) => {
        if (response.data) {
          this.passwordDialog = false;
          this.successChangePassword = true;
          this.passwordResponce = true;
        } else {
          this.errorChangePassword = true;
          this.passwordResponce = true;
        }
      });
    },
  },
  beforeCreate() {
    // this.$http({
    //   method: 'post',
    //   url: '/udata/users/is_auth/.json',
    // }).then((response) => {
    //   if (response.data.result == 1) {
    //     this.login = true;
    //     console.log(this.login);
    //   }
    // });
    // if (this.login == true) {
    //   this.$http({
    //     method: 'post',
    //     url: '/udata/users/get_profile_info/.json',
    //   }).then((response) => {
    //     if (response.data) {
    //       this.profileInfo = response.data;
    //       console.log(response.data);
    //     } else {
    //       console.log('error');
    //     }
    //   });
    // }
  },
  mounted() {
    this.isLogin();
    // if (this.login == false) {
    //     window.location.href = 'https://lk.k7.su/';
    // };
    this.getProfileInfo();
  },
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/styles.sass'

.max-width-600
  max-width: 600px

.cursor-pointer
  cursor: pointer
</style>
